import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header/Header.js"
import NavBar from "../components/Navbar/Navbar"
import Title from "../components/Title/Title"
import InfoContact from "../components/ContactPageComponents/InfoContact/InfoContact"
import Hiring from "../components/Hiring/Hiring.js"
import Footer from "../components/Footer/Footer"

export default function Contact() {
  return (
    <>
      <Header canonicalUrl="https://savannahr.com/contact/" />
      <Helmet>
        <link rel="canonical" href="https://savannahr.com/contact/" />
      </Helmet>
      <NavBar />
      <Title title="GET IN TOUCH" subTitle="Contact Us" />
      <InfoContact />
      <Hiring />
      <Footer />
    </>
  )
}
